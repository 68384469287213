import { FC } from 'react';
import { Box } from '@chakra-ui/react';
import UsersList from '../../private/connect/views/explore/users-list/users-list';
import PublicFilters from '../../private/connect/views/explore/filters/publicFilters';
import SearchHeader from './Header';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

interface ExploreProps {}

const ExplorePublicUsers: FC<ExploreProps> = ({}) => {

  const screenWidth = window.screen.width;
  const { t: translate } = useTranslation();

  return (
    <Box w="100%">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{translate("common.search")}</title>
        </Helmet>
      <SearchHeader></SearchHeader>
      <Box mx={screenWidth <= 1024 ? "1.5rem" : "5rem"} bg="white" flexShrink={0}>
        <PublicFilters />
      </Box>
      <Box mx={screenWidth <= 1024 ? "1.5rem" : "5rem"} bg="white" overflow="hidden" p="0.5rem" rounded="md">
        <UsersList myNetwork={false} isPublic={true} />
      </Box>
    </Box>
  );
};

export default ExplorePublicUsers;
