import { Button } from '@chakra-ui/button';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { FunctionComponent, MouseEventHandler } from 'react';
import CustomInput from '../../../../shared/components/formik/input/input';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { useFormikContext } from 'formik';
import { FaArrowLeft } from 'react-icons/fa';
import { chakra } from '@chakra-ui/react';
const FaArrowLeftAlt = chakra(FaArrowLeft);

interface StepPersonalInfoProps {
  nextStep: MouseEventHandler<HTMLButtonElement>;
  prevStep: MouseEventHandler<HTMLButtonElement>;
}

const StepPersonalInfo: FunctionComponent<StepPersonalInfoProps> = (props) => {
  const { values, submitForm } = useFormikContext();
  return (
    <Box>
      <Flex flexDirection="column">
        <Heading as="h5" size="xs">
          <FormattedMessage translationKey="setupProfileModal.steps.stepInfo.personalInfo.title.text" />
        </Heading>

        <Flex gap={20} pt="1rem">
          <CustomInput placeholder="VAT-number" name={'vatNumber'} type="text" />
          <CustomInput placeholder="Bedrijfsnaam" name={'company'} type="text" />
        </Flex>

        <Flex gap={20} pt="1rem">
          <CustomInput placeholder="Street" name={'seo'} type="street" />
          <CustomInput placeholder="Huisnummer" name={'house number'} type="text" />
        </Flex>

        <Flex gap={20} pt="1rem">
          <CustomInput placeholder="Postcode" name={'postalCode'} type="text" />
          <CustomInput placeholder="Stad" name={'postalCode'} type="city" />
        </Flex>

        <Flex width="44.5%" pt="1rem">
          <CustomInput name="country" placeholder="Land" type="text" />
        </Flex>
      </Flex>

      <Flex ml="auto" gap=".5rem" w="full" justifyContent="start">
        <Button
          leftIcon={<FaArrowLeftAlt color="black" />}
          colorScheme="gray"
          mt="3rem"
          mb="1rem"
          onClick={props.prevStep}
        >
          <FormattedMessage translationKey="setupProfileModal.steps.stepInfo.form.prevStep.button.text" />
        </Button>
        <Button colorScheme="primary" mt="3rem" mb="1rem" onClick={submitForm}>
          <FormattedMessage translationKey="setupProfileModal.steps.stepInfo.contactInfo.submit.button.text" />
        </Button>
      </Flex>
    </Box>
  );
};

export default StepPersonalInfo;
