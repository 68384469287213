import { Stack, Icon, Button, Text, Flex, chakra } from '@chakra-ui/react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

interface BackToDashboardProps {
  label: string;
  redirection?: string;
  user?: string;
}

const BackToDashboard: FC<BackToDashboardProps> = ({ label, redirection, user }) => {
  const navigate = useNavigate();

  const { t: translate } = useTranslation();

  const HoverableFlex = chakra(Flex, {
    baseStyle: {
      _hover: {
        transform: 'scale(1.1)', // Adjust the scale factor as needed
      },
    },
  });

  return (
    <Stack>
      <Button
        onClick={() => {
          redirection ? navigate(redirection) : navigate('/private');
        }}
        backgroundColor="transparent"
        _hover={{ backgroundColor: 'transparent' }}
      >
        <HoverableFlex
          justifyContent="center"
          alignItems="center"
          transition="transform 0.5s ease" // Add a smooth transition for a better effect
        >
          <Icon as={FaAngleLeft} fontSize="20" color="white"></Icon>
          <Text
            display="flex"
            // as="u"
            fontSize="12"
            marginLeft="0.5"
            color="white"
            justifyContent="center"
            alignItems="center"
            textDecoration={'underline'}
          >
            <FaHome style={{ marginRight: 2 }} />
            {translate(label)}
          </Text>
        </HoverableFlex>
        {user && (
          <>
            <Icon as={FaAngleRight} color={'white'}></Icon>
            <Text fontSize="12" marginLeft="0.5" color="white" fontWeight={500}>{user}</Text>
          </>
        )}
      </Button>
    </Stack>
  );
};

export default BackToDashboard;
