import { Card } from '@chakra-ui/card';
import { Box, Divider, Heading, Text } from '@chakra-ui/layout';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';
import { getEducationList } from '../../profile/infrastructure/selector';
import { useProfileApi } from '../../profile/hooks/useProfileApi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EducationModel } from '../../profile/infrastructure/domain/education.model';
import SkeletonVVTIV from '../../../../shared/components/skeleton/skeleton';
import { SkeletonShape } from '../../../../shared/enums/skeletonShape';
import Empty from 'app/shared/components/Empty/Empty';

interface SpecialityProps {
  isPublic?: boolean;
  userId?: string;
}

const Speciality: FC<SpecialityProps> = ({ isPublic, userId }) => {
  const educationList = useSelector(getEducationList);
  const { getEducation, getEducationsByUser } = useProfileApi();
  const { t: translate } = useTranslation();
  const [educations, setEducations] = useState<EducationModel[] | null>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (isPublic) {
      // const getEducations = async () => {
      // setIsLoading(true);
      // const result = await getEducationsByUser(userId || '');
      // setEducations(result);
      // setIsLoading(false);
      // };
      // getEducations();
    } else {
      getEducation();
    }
  }, []);

  useEffect(() => {
    if (educationList) {
      setEducations(educationList);
    }
  }, [educationList]);

  const displaySkeleton = () => {
    return <SkeletonVVTIV type={SkeletonShape.RECTANGLE} noOfLines={2} />;
  };

  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        <FormattedMessage translationKey={'profile.education.title'} />
      </Heading>
      <Divider borderColor="gray.200" />
      <Box width="100%">
        {!educations && displaySkeleton()}
        {educations &&
          educations.length !== 0 &&
          educations?.map((education) => (
            <Box key={education.id} height="200" bgColor="gray.100" paddingY="15" paddingX="10" margin="5">
              <Heading size="sm" color="black">
                {education?.degree + ' | ' + education.school}
              </Heading>
              <Box marginTop="15" color="black">
                <Text>
                  {translate('profile.education.startDate') + ': '}
                  {moment(education?.startDate).format('l') + ' - '}
                  {education?.endDate
                    ? translate('profile.education.endDate') + ': ' + moment(education?.endDate).format('l')
                    : translate('profile.education.untilNow')}
                </Text>
              </Box>
            </Box>
          ))}
        {educations && !educations.length ? <Empty label="profile.education.empty.text" /> : ''}
      </Box>
    </Card>
  );
};

export default Speciality;
