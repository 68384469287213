import { FC, useState, useEffect } from 'react';
import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Center, Flex, Stack, Text } from '@chakra-ui/layout';
import { useSelector } from 'react-redux';
import { getCurrentUser } from '../../profile/infrastructure/selector';
import { Avatar } from '@chakra-ui/react';
import { baseURL } from '../../../../shared/utils/client';
import { selectActiveUser, selectConnectLoading } from '../../connect/infrastructure/selectors';
import { useConnectApi } from '../../connect/hooks/useConnectApi';
import { UserInvitationModel } from '../../connect/infrastructure/domain/UserInvitation.model';
import { useTranslation } from 'react-i18next';
import { FaRocketchat, FaPhoneAlt } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import './profile-header.scss';

interface ProfileHeaderProps {
  isPublic?: boolean;
}

const ProfileHeader: FC<ProfileHeaderProps> = ({ isPublic }) => {
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const connectLoading = useSelector(selectConnectLoading);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>(null);
  const { sendInvitation, checkConnection, deleteConnection, cancelInvitation } = useConnectApi();
  const [isAlreadyConnected, setIsAlreadyConnected] = useState<any>();
  const [isInvitationPending, setIsInvitationPending] = useState<boolean>(false);
  const [invitation, setInvitation] = useState<UserInvitationModel>();
  const { t: translate } = useTranslation();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (isPublic) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
    checkIfIsAlreadyConnected();
  }, [activeUser]);

  useEffect(() => {
    if (invitation) {
      if (invitation.status === 'PENDING') {
        setIsInvitationPending(true);
      } else if (invitation.status === 'REFUSED') {
        setIsInvitationPending(false);
        setIsAlreadyConnected(false);
      } else if (invitation.status === 'ACCEPTED') {
        setIsInvitationPending(false);
        setIsAlreadyConnected(true);
      }
    } else {
      setIsInvitationPending(false);
      setIsAlreadyConnected(false);
    }
  }, [invitation]);

  const checkIfIsAlreadyConnected = async () => {
    if (activeUser) {
      setIsLoading(true);
      const result = await checkConnection(activeUser.id);
      setInvitation(result);
      setIsLoading(false);
    }
  };

  const getLeftProfileInfo = () => {
    return (
      <Stack>
        <Box>
          <Text color="white" fontSize="28">
            {user?.firstName} {user?.lastName}
          </Text>
          <Button
            leftIcon={<FaPhoneAlt />}
            borderRadius="md"
            width="200px"
            textAlign="center"
            px="10"
            py="1"
            bgColor="white"
          >
            <Text color="gray.600">{user?.phoneNumber || translate('connect.explore.phone.notAvailable')}</Text>
          </Button>
          <Text color="white" fontSize="18" maxWidth="200" fontWeight="300" marginTop="5">
            {user?.professionalAddress}
          </Text>
          {!connectLoading && (
            <Text color="white" fontSize="15">
              {user?.type}
            </Text>
          )}
        </Box>
      </Stack>
    );
  };

  const getRightProfileInfo = () => {
    return (
      <Stack>
        <Box>
          {/* <Box>{!connectLoading && displayInviteButton()}</Box> */}
          <Box>
            {id && !connectLoading && (
              <Button
                leftIcon={<FaRocketchat />}
                borderRadius="md"
                textAlign="center"
                width="250px"
                px="10"
                py="1"
                bgColor="white"
              >
                <Text color="gray.600">
                  {translate('connect.explore.chatWith')} {user?.firstName}
                </Text>
              </Button>
            )}
          </Box>
        </Box>
      </Stack>
    );
  };

  return (
    <Box bg={useColorModeValue('primary.600', 'gray.900')} width="100%" height="190" marginBottom="20">
      <Flex alignItems="center" justifyContent="space-between" paddingLeft="10" paddingRight="10">
        {getLeftProfileInfo()}
        {getRightProfileInfo()}
      </Flex>
      <Center>
        {!connectLoading && (
          <Avatar
            bg="rgba(99, 83, 59, 1)"
            src={`${baseURL}/files/${user?.profileCoverImage}`}
            name={`${user?.firstName} ${user?.lastName}`}
            size={'2xl'}
            boxSize="10rem"
          />
        )}
      </Center>
    </Box>
  );
};

export default ProfileHeader;
