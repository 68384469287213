import { FC, useEffect } from 'react';
import { setPrivateTitle } from '../../../infrastructure/store';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex, Spacer, Stack, Button } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useConnectApi } from '../../hooks/useConnectApi';
import { setActiveUser } from '../../infrastructure/store';
import LanguagesTags from '../../../components/languages-tags/languages-tags';
import PersonalIntroduction from '../../../components/personal-introduction/personal-introduction';
import ProfileInfo from '../../../components/profile-info/profile-info';
import SpecialtiesTags from '../../../components/specialties-tags/specialties-tags';
import Speciality from '../../../components/speciality/speciality';
import { selectConnectLoading } from '../../infrastructure/selectors';
import SkeletonVVTIV from 'app/shared/components/skeleton/skeleton';
import { SkeletonShape } from 'app/shared/enums/skeletonShape';
import { selectActiveUser } from 'app/features/private/connect/infrastructure/selectors';
import { FaRocketchat } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

interface UserProfileProps {}

const UserProfile: FC<UserProfileProps> = ({}) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { getUserById } = useConnectApi();
  const activeUser = useSelector(selectActiveUser);
  const { id } = useParams<{ id: string }>();
  const isLoading = useSelector(selectConnectLoading);

  useEffect(() => {
    dispatch(setPrivateTitle({ title: 'ProfilePublic' }));
    if (id) {
      getUserById(id);
    }
    return () => {
      dispatch(setActiveUser(null));
      dispatch(setPrivateTitle({ title: '' }));
    };
  }, []);

  const displaySkeleton = () => {
    return (
      <Box width="100%">
        <Flex justifyContent="flex-start">
          <Stack width="65%" margin="5">
            <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={4} />
            <Spacer />
            <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={4} />
            <Spacer />
            <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={1} />
            <SkeletonVVTIV type={SkeletonShape.RECTANGLE} noOfLines={1} />
          </Stack>
          <Spacer />
          <Spacer />
          <Stack width="35%" margin="5">
            <SkeletonVVTIV type={SkeletonShape.TEXT} noOfLines={1} />
            <SkeletonVVTIV type={SkeletonShape.RECTANGLE} noOfLines={1} />
          </Stack>
        </Flex>
      </Box>
    );
  };

  return isLoading ? (
    displaySkeleton()
  ) : (
    <Box width="100%">
      <Flex justifyContent="flex-start">
        <Stack width="65%" margin="5">
          <SpecialtiesTags />
          <PersonalIntroduction />
          <Speciality isPublic userId={id} />
          <LanguagesTags />
        </Stack>
        <Spacer />
        <Spacer />
        <Stack width="35%" margin="5">
          <ProfileInfo />
          {id && (
            <Box width="100%" display="flex" justifyContent="center">
              <Button
                leftIcon={<FaRocketchat />}
                borderRadius="md"
                textAlign="center"
                colorScheme="primary"
                color="white"
                mt="5"
              >
                {translate('connect.explore.chatWith')} {activeUser?.firstName}
              </Button>
            </Box>
          )}
        </Stack>
      </Flex>
    </Box>
  );
};

export default UserProfile;
