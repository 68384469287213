import { ModalBody, ModalContent, ModalHeader } from '@chakra-ui/modal';
import { Flex, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { FC } from 'react';
import Stepper, { StepsProps } from '../../../../shared/components/stepper/stepper';
import CompleteStepperPage from './../complete-stepper-page/complete-stepper-page';
import StepBasicInfo from './../steps/stepBasicInfo';
import StepPersonalInfo from '../steps/stepPersonalInfo';
import { StepperUserInfoSchema } from './../validateSchema/steps.schema';
import FormattedMessage from '../../../../shared/components/translation/formattedMessage';

interface SetupProfileFormProps {
  onComplete: () => void;
}

const STEPS: StepsProps[] = [{ StepComponent: StepBasicInfo }, { StepComponent: StepPersonalInfo }];

const SetupProfileForm: FC<SetupProfileFormProps> = ({ onComplete }) => {
  return (
    <ModalContent>
      <ModalBody>
        <Formik
          initialValues={{
            email: '',
            password: '',
            keepMeIn: false,
          }}
          validationSchema={StepperUserInfoSchema}
          onSubmit={(values, { setFieldError }) => {
            onComplete();
          }}
        >
          {({ handleSubmit, setFieldValue, values }) => (
            <Form onSubmit={handleSubmit}>
              <Flex justifyContent="center">
                <Text fontWeight="bold">
                  <FormattedMessage translationKey="setupProfileModal.steps.title.text" upperCase />
                </Text>
              </Flex>

              <Stepper onComplete={() => console.log(false)} steps={STEPS} LastPage={CompleteStepperPage} />
            </Form>
          )}
        </Formik>
      </ModalBody>
    </ModalContent>
  );
};

export default SetupProfileForm;
