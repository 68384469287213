import { Box, Flex, Avatar, Text, Badge, Button } from '@chakra-ui/react';
import { FC } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { baseURL } from '../../../../../../shared/utils/client';
import './card.scss'
import { useTranslation } from 'react-i18next';
interface UserCardProps {
  user: any;
}
const UserProfile: FC<UserCardProps> = ({ user }) => {
  const navigate = useNavigate();
  const {t: translate} = useTranslation()

  const screenWidth = window.screen.width;

  return (
    <Box
      boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06);"
      p="2rem"
      rounded="md"
      w="100%"
      _hover={{ cursor: 'pointer', transform: 'translateY(-2px)', boxShadow: 'lg' }}
    >
      <Flex flexDirection={screenWidth <= 768 ? 'column' : 'row'} gap="2rem" alignItems={'center'}>
        <Avatar
          size="xl"
          boxSize="6.5rem"
          src={user?.profileImage ? `${baseURL}/files/${user.profileImage}` : 'default-avatar.png'}
        />

        <Flex direction="column" gap=".5rem" flexGrow={1}>
          <Flex gap=".5rem" alignItems="center">
            <Text fontSize="2xl" as={'h2'} fontWeight="bold">
              {user?.firstName + ' ' + user?.lastName}
            </Text>
          </Flex>

          <Flex direction="row" align="center">
            <Box as={FaBuilding} color="orange.400" />
            <Text ml={1} color="gray.500">
              {user?.companyCity + ' ' + user?.companyStreet + ' ' + user?.companyHouseNumber}
            </Text>
          </Flex>

          <Text
            sx={{ mt: 4, color: 'gray.700' }}
            noOfLines={4}
            dangerouslySetInnerHTML={{ __html: user?.aboutMe }}
          ></Text>
          {/* <Flex gap="1" minH="2rem">
            {['test', 'test', 'test'].map((data: string, index: number) => (
              <Badge className='special-badge' key={`${data}${index}`} colorScheme="gray" color="gray.600" rounded="md" px={4} fontWeight={'500'} py="2">
                {data}{' '}
              </Badge>
            ))}
          </Flex> */}
          <Button
            size="sm"
            width={'max-content'}
            flexGrow={0}
            bg={'primary.600'}
            mt={'10px'}
            color={'white'}
            onClick={() => navigate(`${process.env.PUBLIC_URL}/profile/${user?.id}`)}
            rounded={'md'}
            _hover={{
              transform: 'translateY(-2px)',
              boxShadow: 'lg',
            }}
          >
            {translate('common.showDetails')}
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default UserProfile;
