import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, Heading, Input, Text, Textarea, Card, CardFooter, Divider, Checkbox, Link } from '@chakra-ui/react';
import { getCurrentUser } from 'app/features/private/profile/infrastructure/selector';
import { selectActiveUser } from 'app/features/private/connect/infrastructure/selectors';
import { Form, Formik } from 'formik';
import { usePublicApi } from '../../hooks/usePublicApi';

interface MessageInterface {
  email: string;
  name: string;
  message: string;
  phoneNumber: string;
  agreeTerms: boolean;
}
interface MessageSenderProps {
  id: any
}
const MessageSender: FC<MessageSenderProps> = ({ id }) => {
  const { t: translate } = useTranslation();
  const { postSendMessage } = usePublicApi()
  const currentUser = useSelector(getCurrentUser);
  const activeUser = useSelector(selectActiveUser);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    if (activeUser) {
      setUser(activeUser);
    } else {
      setUser(currentUser);
    }
  }, [activeUser]);

  const onSendMessage = (values: MessageInterface, initialValues: any) => {
    postSendMessage(id, values, initialValues)
  };

  const getForm = (setFieldValue: (arg: string, value: any) => void, values: MessageInterface) => {
    return (
      <Box m="2rem">
        <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: '0', md: '1rem' }}>
          <Box flex="1">
            <Text fontWeight="medium" fontSize="15">
              {translate('profile.message.email')}
            </Text>
            <Input
              size="sm"
              my=".5rem"
              required
              placeholder={translate('profile.message.email') || ''}
              onChange={({ target }) => setFieldValue('email', target.value)}
              value={values.email}
            />
          </Box>
          <Box flex="1">
            <Text fontWeight="medium" fontSize="15">
              {translate('profile.message.name')}
            </Text>
            <Input
              size="sm"
              my=".5rem"
              required
              placeholder={translate('profile.message.name') || ''}
              onChange={({ target }) => setFieldValue('name', target.value)}
              value={values.name}
            />
          </Box>
        </Flex>
        <Text fontWeight="medium" fontSize="15">
          {translate('profile.message.help')}
        </Text>
        <Textarea required size="sm" onChange={({ target }) => setFieldValue('message', target.value)} value={values.message} />

        <Text fontWeight="medium" fontSize="15">
          {translate('profile.message.phone')}
        </Text>
        <Input
          size="sm"
          my=".5rem"
          placeholder={translate('profile.message.phone') || ''}
          onChange={({ target }) => setFieldValue('phoneNumber', target.value)}
          value={values.phoneNumber}
        />
        <Checkbox onChange={({ target }) => setFieldValue('agreeTerms', target.checked)} isChecked={values.agreeTerms}>
          {translate('profile.message.agree')}
          <Link color="teal.500" href="#" mx="1">
            {translate('profile.message.terms')}
          </Link>
          {translate('profile.message.and')}
          <Link color="teal.500" href="#" mx="1">
            {translate('profile.message.privacy')}
          </Link>
        </Checkbox>
        {/* <Divider borderColor="gray.200" my={'1rem'} /> */}

        {/* <Text fontSize="15">{translate('profile.message.thanks')}</Text> */}
      </Box>
    );
  };
  return (
    <Card width="100%">
      <Heading size="md" textAlign="left" padding={25}>
        {`${translate('profile.message.send')}  ${user?.firstName} ${user?.lastName}`}
      </Heading>
      <Divider borderColor="gray.200" />
      <Formik
        initialValues={{
          email: '',
          name: '',
          message: '',
          phoneNumber: '',
          agreeTerms: true,
        }}
        onSubmit={(values, { resetForm }) => {
          onSendMessage(values, resetForm);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <Form onSubmit={handleSubmit}>
            {getForm(setFieldValue, values)}
            <Divider borderColor="gray.200" />
            <CardFooter>
              <Button
                type="submit"
                size="sm"
                ml={{ base: '0', md: 'auto' }}
                rounded="md"
                display="block"
                colorScheme="primary"
              >
                {translate('profile.action.send')}
              </Button>
            </CardFooter>
          </Form>
        )}
      </Formik>
    </Card>
  );
};

export default MessageSender;
