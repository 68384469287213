import React, { useState, FC } from 'react';
import { Menu, MenuButton, MenuList, MenuItem, Flex, Text, Img, MenuButtonProps, Icon } from '@chakra-ui/react';
import imageFr from '../../../../assets/images/flags/fr.png';
import imageNl from '../../../../assets/images/flags/be.webp';
import imageEn from '../../../../assets/images/flags/en.png';
import { useTranslation } from 'react-i18next';
import { AiOutlineDown } from 'react-icons/ai';

const languages: LanguageData = {
  en: {
    name: 'English',
    code: 'en',
    image: imageEn,
  },
  nl: {
    name: 'Nederlands',
    code: 'nl',
    image: imageNl,
  },
  fr: {
    name: 'Français',
    code: 'fr',
    image: imageFr,
  },
};
type LanguageData = {
  [key: string]: {
    name: string;
    code: string;
    image: string;
  };
};

interface LanguageSelectProps extends MenuButtonProps {
  isTextShown?: boolean;
  color?: 'white' | 'black';
}

const LanguageSelect: FC<LanguageSelectProps> = ({ color = 'white', isTextShown = true, ...props }) => {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState<string>('nl');
  return (
    <Menu>
      <MenuButton color={color} py=".5rem" px=".5rem" {...props}>
        <Flex alignItems="center" justifyContent="center">
          {/* <Img alt={languages[language].name} w="1.5rem" h="1.5rem" mr=".3rem" src={languages[language].image} /> */}
          <Text fontWeight="medium" px="2px" textTransform="uppercase">
            {isTextShown && languages[language].code}
          </Text>
          <Icon as={AiOutlineDown} mt=".2rem" ml=".3rem" />
        </Flex>
      </MenuButton>
      <MenuList bg="transparent" w="max-content" minW="5rem">
        {Object.keys(languages).map(
          (data) =>
            data != language && (
              <MenuItem
                bg="transparent"
                key={languages[data].code}
                color={color}
                onClick={() => {
                  setLanguage(data);
                  i18n.changeLanguage(languages[data].code);
                }}
                textTransform="uppercase"
              >
                {/* <Img
                  bg="transparent"
                  alt={languages[data].name}
                  w="1.2rem"
                  h="1.2rem"
                  mr=".3rem"
                  src={languages[data].image}
                />{' '} */}
                {languages[data].code}
              </MenuItem>
            )
        )}
      </MenuList>
    </Menu>
  );
};

export default LanguageSelect;
