import { useEffect } from 'react';
import { Avatar, Box, Button, Center, Divider, Flex, Heading, Img, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Text, useColorModeValue } from '@chakra-ui/react';
import { FaFly, FaUserCircle, FaBell, FaPencilAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { getPrivateTitle } from '../../features/private/infrastructure/selectors';
import { useNavigate } from 'react-router';
import { getCurrentUser } from '../../features/private/profile/infrastructure/selector';
import { baseURL } from '../utils/client';
import { IoMdLogOut, IoMdSettings } from 'react-icons/io';
import { AiFillWechat } from 'react-icons/ai';
import LanguageSelect from '../components/language-select/LanguageSelect';
import { UserRoles } from '../enums/userRoles';
import BackToDashboard from '../components/back-to-dashboard/back-to-dashboard';
import { useTranslation } from 'react-i18next';
import FormattedMessage from '../components/translation/formattedMessage';
import { getNewsSelector } from '../../features/private/notification/infrastructure/selector';
import { useNotificationsApi } from '../../features/private/notification/hooks/useNotificationApi';
import { GetNewsResponse } from '../../features/private/notification/domain/response/getNews.response';
import './private-header.scss';
import moment from 'moment';

const currentPlanByRole = {
  [UserRoles.ADMIN]: 'Admin',
  [UserRoles.STUDENT]: 'Student',
  [UserRoles.USER]: 'User',
};

const PrivateHeader = () => {
  const { t: translation } = useTranslation();
  const currentUser = useSelector(getCurrentUser);
  const news = useSelector(getNewsSelector);
  const { getNotifications } = useNotificationsApi();

  useEffect(() => {
    getNotifications();
  }, []);

  const navigate = useNavigate();
  const pageTitle = useSelector(getPrivateTitle);

  const onLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('firstLogin');
    navigate('/login');
  };
  

  if (!currentUser) {
    return null;
  }
  return (
    <>
      <Box
        bg={useColorModeValue('primary.600', 'gray.900')}
        px={4}
        marginBottom={pageTitle !== 'Profile' && pageTitle !== 'ProfilePublic' ? '10' : '0'}
      >
        <Flex justify={"center"} position={'absolute'} left={0} right={0} marginTop={6} width={"max-content"} marginX={'auto'} className='vvtiv-white-logo'>
            <Img w="8rem" src="https://vvtiv.be/assets/img/vvtiv-05-4.png"></Img>
          </Flex>
        <Flex h={16} alignItems={'center'} justifyContent={pageTitle !== String(translation('dashboard.header.title')) ? 'space-between' : 'end'}>
          {pageTitle !== String(translation('dashboard.header.title')) &&
            <Flex justify="end">
              <BackToDashboard label="header.back_to_dashboard" />
            </Flex>
          }
          <Flex justify="end">
            <LanguageSelect />
            <Menu>
              <MenuButton as={Button} rounded={'full'} variant={'link'} cursor="pointer" minW={0}>
                <Avatar bg="rgba(99, 83, 59, 0)" size={'md'} icon={<FaBell fontSize="1.2rem" />}></Avatar>
              </MenuButton>
              <MenuList
                className="news-list"
                style={{ width: 350 }}
                alignItems={'center'}
                maxH="400px"
                overflowY="auto"
              >
                {(news || []).map((el: GetNewsResponse, index: number) => {
                  return (
                    <div key={index}>
                      <MenuItem>
                        <Box display="flex" alignItems="center">
                          <Avatar bg="primary.500" size={'sm'} marginRight="3" icon={<FaBell />}></Avatar>
                          <Box display="block" alignItems="center">
                            <Text as="b">{el.title}</Text>
                            <Text style={{ color: 'gray' }}>{el.content}</Text>
                          </Box>
                        </Box>
                        <br />
                      </MenuItem>
                      {index !== news.length - 1 && <Divider />}
                    </div>
                  );
                })}
              </MenuList>
            </Menu>
            <Menu>
              <MenuButton as={Button} rounded={'full'} variant={'link'} cursor="pointer" minW={0}>
                <Avatar
                  bg="rgba(99, 83, 59, 0.8)"
                  boxSize="2.4rem"
                  src={`${baseURL}/files/${currentUser.profileCoverImage}`}
                  name={`${currentUser.firstName} ${currentUser.lastName}`}
                ></Avatar>
              </MenuButton>
              <MenuList alignItems={'center'}>
                <br />
                {/* <Center>
                  <p>{currentUser.firstName + ' ' + currentUser.lastName}</p>
                </Center> */}
                <br />
                
                <MenuDivider />
                <MenuItem onClick={() => navigate('/private/profile')}>
                  <FaUserCircle /> <Text marginLeft="2">{String(translation('header.menu.profile'))}</Text>
                </MenuItem>
                <MenuItem onClick={() => navigate('profile/edit/information')}>
                  <FaPencilAlt /> <Text marginLeft="2">{String(translation('dashboard.card.edit.profile.text'))}</Text>
                </MenuItem>
                <MenuItem onClick={() => navigate('profile/edit/information')}>
                  <IoMdSettings />
                  <Text marginLeft="2">{String(translation('header.menu.settings'))}</Text>
                </MenuItem>
                <MenuItem>
                  <AiFillWechat />
                  <Text marginLeft="2">{String(translation('header.menu.sent_message'))}</Text>
                </MenuItem>
                <MenuItem>
                  <FaFly />
                  <Text marginLeft="2">{String(translation('header.menu.manual'))}</Text>
                </MenuItem>
                <MenuItem onClick={onLogout}>
                  <IoMdLogOut />
                  <Text marginLeft="2">{String(translation('header.menu.logout'))}</Text>
                </MenuItem>
                <Divider />
                <Box marginLeft="3" marginTop="3" paddingRight="2">
                  <Flex alignItems="center" marginY="1px">
                    <Text fontSize="14px" as="u">
                      {String(translation('header.menu.current_plan'))}:
                    </Text>
                    <Text fontSize="12px" marginX="5px" color="gray">
                      {currentPlanByRole[currentUser.role] || '_'}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" marginY="1px">
                    <Text fontSize="14px" as="u">
                      {String(translation('header.menu.next_payment'))}:
                    </Text>
                    <Text fontSize="12px" marginX="5px" color="gray">
                      {currentUser.subscriptions && moment(currentUser.subscriptions[currentUser.subscriptions.length - 1].ends_at).format("DD-MM-YYYY") || '_'}
                    </Text>
                  </Flex>
                </Box>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Flex justify="center" marginX="0">
          <Box paddingTop="70" justifyContent="center">
            <Heading size="lg" color="white" mb="5">
              <FormattedMessage
                translationKey={
                  pageTitle && pageTitle !== 'Profile' && pageTitle !== 'ProfilePublic' ? translation(pageTitle) : ''
                }
              />
            </Heading>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default PrivateHeader;
